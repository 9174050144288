.order-viewer-item-row {
  display: grid;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: rgb(255, 252, 212);
  grid-template-columns: 20% 80%;
}

.order-viewer-item-qty {
  font-weight: bold;
  grid-row: 1;
  text-align: center;
}

.order-viewer-item-product {
  font-weight: bold;
  grid-row: 1;
  min-height: 20px;
}

.order-viewer-item-note {
  color: #bd1898;
  grid-column: 1 / 3;
  text-align: left;
  padding-left: 50px;
}
