$primaryBG: rgb(143, 180, 204);
$secondaryBG: rgb(166, 173, 176);

.r-calendar-main-table td {
  padding: 0px 0;
  line-height: 2.25rem;
}

#calendar-cell-empty {
  min-width: 70px;
  max-width: 70px;
}

.r-calendar-main-table tr th:first-child,
.r-calendar-main-table tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  white-space: nowrap;
}

.r-calendar-main-table tr td:first-child > div {
  padding: 0 15px;
  text-align: right;
  // color: #555;
}

// .r-calendar .r-calendar-main-table tbody tr:nth-of-type(odd),
.r-calendar-main-table
  tbody
  tr:not(:first-child)
  td:not(.calendar-category-row):nth-child(even) {
  @apply bg-zinc-100;
}

.r-calendar-main-table thead th {
  font-weight: normal;
  cursor: pointer;
}

.r-calendar-head-month,
.r-calendar-main-table tr td:first-child > div {
  color: #666;
  font-size: 0.8em;
  padding: 0 7px;
  text-transform: uppercase;
}

.r-calendar-main-table tr td:first-child > div {
  border-right: 1px solid $primaryBG;
}

.r-calendar-main-table thead {
  z-index: 10;
}
