@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@flaticon/flaticon-uicons/css/all/all";

html {
  min-height: 100%;
}

body {
  background-color: rgb(248 250 252);
  min-height: 100%;
}

#root {
  min-height: 100%;
}

body {
  font-family: "Roboto", "Ubuntu", "Roboto Mono", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
p {
  font-family: Roboto, monospace, sans-serif;
}

input {
  font-size: small !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.monospace {
  font-family: mono, Courier, monospace;
}

input[type="text"][disabled] {
  @apply bg-gray-200;
}

.rotateFull {
  transform: rotate(135deg);
}

.custom-transition {
  transition-property: width;
}

.carousel.carousel-slider {
  overflow: visible;
}
.carousel .control-dots {
  bottom: -44px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  @apply z-0;
}

.custom-dot {
  width: 10px !important;
  height: 10px !important;

  @apply bg-gray-200 rounded-full w-3 h-3 cursor-pointer;
}
.custom-dot.active {
  @apply bg-blue-presik !w-[40px] transition duration-100;
  cursor: pointer;
}

.melhous-menu-description {
  font-family: "Oswald", sans-serif;
}
.melhous-menu-title {
  font-family: "Young Serif", serif;
}
.headerEvents #board-header {
  display: none;
}

.scrolling-text {
  white-space: nowrap;
  overflow: hidden;
  animation: scrollText 20s linear infinite;
  /* max-width: 50vw; */
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: black;
  transform-origin: 0%;
  z-index: 30000;
}

.content-mask {
  -webkit-mask-image: linear-gradient(
    to right,
    rgb(0 0 0) 0%,
    rgb(0, 0, 0) 25%,
    rgb(0, 0, 0) 75%,
    rgba(0, 0, 0, 0) 100%
  );
}

@keyframes scrollText {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 780px) {
  .content-mask {
    display: none;
  }
}
