.slider-container {
  overflow: hidden !important;
  margin-left: -40px;
  margin-right: -40px;
}

.slider-list {
  width: 120% !important;
}

.slide {
  text-align: center;
  width: 100% !important;
  margin: 0px 10px;
}

.slider {
  overflow: visible;
}
