.tags-melhous {
  clip-path: polygon(17% 0%, 100% 0%, 83% 100%, 0% 100%);
}
.tags-melhous-cat {
  clip-path: polygon(12% 0%, 100% 0%, 88% 100%, 0% 100%);
}
#modal-melhous .carousel .control-dots {
  bottom: -23px !important;
}

#modal-melhous .carousel .custom-dot.active {
  height: 3px !important;
}
#modal-melhous .custom-dot-melhous {
  background: #c4c4c5 !important;
  width: 6px !important;
}
#modal-melhous .custom-dot.active {
  width: 21px !important;
  @apply !bg-blue-presik;
}

#modal-melhous .slide.selected {
  z-index: 10;
}
