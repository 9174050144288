.button-panel {
  margin: 20px !important;
  padding: 20px !important;
  width: 200px;
  height: 70px;
}

.card-content {
  padding: 20px;
  cursor: auto;
}

/*
.input-panel {
  margin: 20px !important;
  padding: 20px !important;
  width: 400px !important;
  height: 90px !important;
  font-weight: bold;
}

.row-panel {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.grid-dash-model {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 25px !important;
} */
